import { render, staticRenderFns } from "./SetGoalsCarouselSlide.vue?vue&type=template&id=1a7c1ee0&scoped=true&"
import script from "./SetGoalsCarouselSlide.vue?vue&type=script&lang=js&"
export * from "./SetGoalsCarouselSlide.vue?vue&type=script&lang=js&"
import style0 from "./SetGoalsCarouselSlide.vue?vue&type=style&index=0&id=1a7c1ee0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7c1ee0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SplitView: require('/opt/build/repo/apps/shop/components/storyblok/SplitView/SplitView.vue').default})
