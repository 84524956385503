//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SplitView from '~/components/SplitView.vue';
import FCImage from '~/components/FCImage.vue';
import AppImage from './AppImage.vue';
import WomanPunchingImage from './WomanPunchingImage.vue';
import PictureResponsive from '../PictureResponsive.vue';

export default {
  name: 'SetGoalsCarouselSlide',
  components: {
    SplitView,
    AppImage,
    WomanPunchingImage,
    FCImage,
    PictureResponsive,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    slideDescriptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    slideTitle() {
      return (id) => `slide__title-${id}`;
    },
    slideText() {
      return (id) => `slide__text-${id}`;
    },
  },
  methods: {
    isImageWithText(name) {
      return name === 'AppImage';
    },
    getProvider(src) {
      return src.includes('a.storyblok.com') ? 'storyblok' : 'fcProvider';
    },
  },
};

