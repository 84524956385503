//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from '~/components/FCImage.vue';

export default {
  name: 'AppImage',
  components: {
    FCImage,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
};
