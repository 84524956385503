//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Carousel, Slide } from '@fc/angie-ui';
import SetGoalsCarouselSlide from '../home/SetGoalsCarouselSlide.vue';

export default {
  name: 'SetGoalsCarousel',
  components: {
    Carousel,
    Slide,
    SetGoalsCarouselSlide,
  },
  computed: {
    slides() {
      return [
        {
          id: 'slide-1',
          name: 'PunchingBag',
          src: 'https://a.storyblok.com/f/152150/1071x825/154c15bcd3/fc-home-man-punching-bag-006.jpg',
          alt: 'Man punching FightCamp free standing bag',
        },
        {
          id: 'slide-2',
          name: 'PartnerWorkouts',
          src: 'https://a.storyblok.com/f/152150/562x422/82c5848130/split-image-boxers.jpg',
          alt: '2 FightCamp users looking into camera with determined looks. They have sweated a little after doing FightCamp workout. They are wearing FightCamp boxing gloves.',
        },
        {
          id: 'slide-3',
          name: 'FightcampTrainersImage',
          src: 'https://a.storyblok.com/f/152150/1071x825/010ff5911c/fc-home-woman-punching-bag-001-1.jpg',
          alt: 'Trainer Jess leading a class',
        },
      ];
    },
    slideDescriptions() {
      return [
        {
          id: 'slide-1',
          header: 'Set Goals. Crush Goals. Repeat.',
          text: 'Climb the leaderboard & beat your personal best. Track your progress with post-workout summaries, and hit punch goals designed to push you to your limits.',
        },
        {
          id: 'slide-2',
          header: 'Partner Workouts',
          text: 'Two trackers for you, and two for a friend. Instead of solo sweat sessions, Partner Workouts allow you and another person in your home to workout at the same time — cheering each other on and fighting through rounds together.',
        },
        {
          id: 'slide-3',
          header: 'Unlimited Workouts',
          text: 'Fight boredom with 1,000+ boxing, kickboxing, and core workouts. Our on-demand library will keep things fresh with new weekly content.',
        },
      ];
    },
  },
};
